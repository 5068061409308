<template>
  <div>
    <b-container fluid>
      <h2 class="d-flex">Consent Lists</h2>
      <b-button v-if="isAdmin" @click="newListHandle" variant="primary">New Consent List</b-button>
      <p v-if="consentLists === null">You have no consent lists linked to your account.</p>
      <div v-else>
        <p v-if="!isAdmin">
          This page allows you to see the consents you have collected from your clients for using
          Reducer.
          <br />To get consent from your clients they are sent an email with a unique link and form.
          We then collate this information into a list of consents. <br />To create a new consent
          list, talk to your Reducer Account manager.
        </p>
        <div>
          <b-pagination
            v-if="consentLists.length > perPage"
            align="right"
            :total-rows="consentLists.length"
            :per-page="perPage"
            v-model="currentPage"
            :first-text="'\u00AB'"
            :prev-text="'\u2039'"
            :next-text="'\u203A'"
            :last-text="'\u00BB'"
            :ellipsis-text="'\u2026'"
          />
        </div>
        <b-table
          id="content-table"
          :sort-by="sortBy"
          :sort-desc="true"
          :items="consentLists"
          :fields="consentsFields"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template v-slot:cell(deadline)="row">{{ formatDate(row.item.deadline) }}</template>

          <template v-slot:cell(createdDate)="row">{{ formatDate(row.item.createdDate) }}</template>

          <template v-slot:cell(name)="row">{{ max150(row.item.name) }}</template>

          <template v-slot:cell(description)="row">{{ max150(row.item.description) }}</template>

          <template v-slot:cell(url)="row">
            <a :href="row.item.url" target="_blank">{{ row.item.url }}</a>
          </template>

          <!-- Consent Type -->
          <template v-slot:cell(consentType)="row">
            <span v-if="row.item.consentType === 'optIn'" class="badge badge-pill badge-success"
              >Opt In</span
            >
            <span
              v-else-if="row.item.consentType === 'optOut'"
              class="badge badge-pill badge-primary"
              >Opt Out</span
            >
            <span v-else class="badge badge-pill badge-info">{{ row.item.consentType }}</span>
          </template>

          <!-- Action Button -->
          <template v-slot:cell(actions)="row">
            <b-dropdown text="Actions" lazy>
              <!-- View Consents -->
              <b-dropdown-item
                :to="{ name: 'consents', params: { consentListId: row.item.consentListId } }"
              >
                <font-awesome-icon
                  :icon="['fas', 'info-circle']"
                  class="mr-1 text-muted space-right"
                />View Consents
              </b-dropdown-item>

              <!-- Edit Consent List -->
              <b-dropdown-item
                v-if="isAdmin"
                :to="{
                  name: 'consent-list-data',
                  params: { consentListId: row.item.consentListId, viewMode: false }
                }"
              >
                <font-awesome-icon
                  :icon="['far', 'edit']"
                  class="mr-1 text-muted space-right"
                />Edit Consent List
              </b-dropdown-item>

              <!-- View Consent List -->
              <b-dropdown-item
                v-if="isAdmin"
                :to="{
                  name: 'consent-list-data',
                  params: { consentListId: row.item.consentListId }
                }"
              >
                <font-awesome-icon :icon="['far', 'eye']" class="mr-1 text-muted space-right" />View
                Consent List
              </b-dropdown-item>

              <!-- Copy URL -->
              <div>
                <b-dropdown-divider />
                <b-dropdown-item
                  @click="copyUrlHandler(row.item.url)"
                  v-clipboard:copy="row.item.url"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                  <font-awesome-icon
                    :icon="['far', 'clipboard']"
                    class="mr-1 text-muted space-right"
                  />Copy URL
                </b-dropdown-item>
              </div>

              <!-- Delete Consent List -->
              <div v-if="isAdmin">
                <b-dropdown-divider />
                <b-dropdown-item @click="deleteHandler(row.item.consentListId)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="mr-1 text-muted space-right"
                  />Delete Consent List
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-if="consentLists.length > perPage"
          align="right"
          :total-rows="consentLists.length"
          :per-page="perPage"
          v-model="currentPage"
          :first-text="'\u00AB'"
          :prev-text="'\u2039'"
          :next-text="'\u203A'"
          :last-text="'\u00BB'"
          :ellipsis-text="'\u2026'"
        />
      </div>
      <b-modal
        id="delete-consent-list-modal"
        v-model="showModal"
        title="Consent List Deletion Confirmation"
        @ok="confirmedDelete"
      >
        <p class="my-2">Are you sure you would like to delete this consent list?</p>
      </b-modal>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "ConsentLists",
  props: {
    consentLists: Array,
    isAdmin: Boolean
  },
  data() {
    return {
      title: "Consent Lists",
      tidyConsentLists: [],
      consentsFields: [
        { key: "actions", label: "", class: "text-center align-middle" },
        { key: "name", sortable: true },
        { key: "description", sortable: true },
        { key: "deadline", sortable: true, label: "Connect Date" },
        { key: "createdDate", sortable: true, label: "Created" },
        { key: "consentType", sortable: true, label: "Type" },
        { key: "consents", sortable: true, label: "Consents" },
        { key: "url", sortable: false, label: "Link to Form" }
      ],
      sortBy: "createdDate",
      showModal: false,
      deleteId: null,
      currentPage: 1,
      perPage: 50
    };
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    max150: FormatHelper.max150,
    newListHandle() {
      this.$router.push({
        name: "consent-list-data",
        params: { consentListId: "new" }
      });
    },
    deleteHandler(id) {
      this.deleteId = id;
      this.showModal = true;
    },
    confirmedDelete() {
      this.$emit("delete", this.deleteId);
    },
    copySuccess() {
      this.$emit("message", "URL copied successfully.", "success");
    },
    copyError() {
      this.$emit("message", "Couldn't copy URL.", "warning");
    }
  }
};
</script>
