<template>
  <b-container fluid>
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <div v-if="busy">
      <Busy primary :size="3" text="Loading..." />
    </div>
    <div v-else>
      <ConsentLists
        :consentLists="consentLists"
        :isAdmin="isAdmin"
        v-on:delete="deleteConsentList"
        v-on:message="showMessage"
      />
    </div>
  </b-container>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import CognitoAuth from "@/cognito/cognitoauth";
import ConsentLists from "@/components/ConsentLists";
import Busy from "@/components/Busy";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";

export default {
  props: {
    userId: String
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      title: "Consent Lists",
      isAdmin: null,
      consentLists: [],
      busy: true
    };
  },
  components: {
    ConsentLists,
    Busy
  },
  watch: {
    userId: function () {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();

      let userId = this.$route.params.userId;
      let param = userId != null ? "?userId=" + userId : "";

      let client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}consentlists` + param)
        .then((response) => {
          Console.log(response);
          this.consentLists = response.data.consentLists;

          if (this.consentLists) {
            // Overrite consents with their length
            for (let i = 0; i < this.consentLists.length; i++) {
              this.consentLists[i].consents =
                this.consentLists[i].consents != null ? this.consentLists[i].consents.length : 0;
            }
          }
          this.busy = false;
        })
        .catch((e) => {
          Console.error(e);
          this.busy = false;
          this.showMessage("Couldn't load data.", "warning");
        });
    },
    async deleteConsentList(id) {
      this.busy = true;
      let client = await ApiHelper.http();
      await client
        .delete(`${ApiHelper.endPoint()}consentlists/` + id)
        .then((response) => {
          Console.log(response);
          this.fetchData();
        })
        .catch((e) => {
          Console.error(e);
          this.busy = false;
          this.showMessage("There was a problem deleting consent list.", "warning");
        });
    },
    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>